import React from "react";

const RetroLandingPage = () => {
  const styles = {
    container: {
      backgroundColor: "#fefcbf",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      //   padding: "1rem",
      fontFamily: "monospace",
    },
    header: {
      textAlign: "center",
      marginBottom: "2rem",
    },
    title: {
      fontSize: "3.75rem",
      fontWeight: "bold",
      color: "#6b46c1",
      marginBottom: "0.5rem",
    },
    subtitle: {
      fontSize: "1.5rem",
      color: "#dd6b20",
    },
    main: {
      backgroundColor: "white",
      border: "4px solid #6b46c1",
      padding: "1.5rem",
      maxWidth: "28rem",
      width: "100%",
    },
    h2: {
      fontSize: "1.875rem",
      fontWeight: "bold",
      color: "#2f855a",
      marginBottom: "1rem",
    },
    ul: {
      listStyleType: "disc",
      paddingLeft: "1.5rem",
      color: "#2b6cb0",
    },
    button: {
      marginTop: "1.5rem",
      backgroundColor: "#e53e3e",
      color: "white",
      padding: "0.5rem 1rem",
      borderRadius: "0.25rem",
      border: "none",
      cursor: "pointer",
    },
    footer: {
      marginTop: "2rem",
      color: "#6b46c1",
    },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Hello Researcher!</h1>
        <p style={styles.subtitle}>A personalized weekly newsletter</p>
      </header>

      <main style={styles.main}>
        <h2 style={styles.h2}>What we offer weekly:</h2>
        <ul style={styles.ul}>
          <li>Newly released research papers relevant to your research.</li>
          <li>Introduction to other researchers in your field.</li>
          <li>New Research ideas for your next award-winning project.</li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="https://forms.gle/dNZGD783ewM5a83JA">
            <button style={styles.button}>Subscribe</button>
          </a>
        </div>{" "}
      </main>

      <footer style={styles.footer}>&copy; {new Date().getFullYear()}</footer>
    </div>
  );
};

export default RetroLandingPage;
